import React from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Nav,
  NavDropdown,
  Navbar,
  Offcanvas,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import img1 from "../photo/viren.jpg";
import { TypeAnimation } from "react-type-animation";
import img2 from "../photo/img-2.webp";
import img3 from "../photo/img3.png";
import img4 from "../photo/img4.png";


export default function Home() {
  return (
    <>
      <>
        <Navbar expand="lg" className="bg-body-tertiary ">
          <Container fluid>
            <Navbar.Brand href="" id="submit">RADADIYA</Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-`}>
                 Viren Radadiya
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="#action1">About</Nav.Link>
                  <Nav.Link href="#action2">Project</Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </>

      <Container fluid>
        <Row>
          <div className="background col-lg-12">
            <div className="v2">
              <div>
                <img className="v1 " src={img1} />
              </div>
              <div>
                <h1 className="hello"> Viren Radadiya</h1>
              </div>
            </div>

            <div className="text-2">
            <h1 className="dd">Hello I Am Web Developer</h1>
            </div>

              <div className="col-lg-12 mt-5 d-flex justify-content-center ">


                <div>
                  <a href="#table">
                  <button className="btn-1 mb-5 ">
                    Hire me
                  </button>
                  </a>
                </div>

                <div>
                  <button className="btn-2 ">Download CV</button>
                </div>

              </div>
          </div>
        </Row>
      </Container>

      <Container id="action1">
        <Row className="mt-5 mb-5">
          <div className="col-lg-6">
            <img className="img-2 img-fluid" src={img2} />
          </div>
          <div className="col-lg-6">
            <h1 className="text-3" >About Me</h1>
            <p className="text-4">
              Transitioning into web development has ignited a new passion
              within me. While my background differs, I've enthusiastically
              dived into HTML, CSS, Bootstrap, JavaScript, React Js, Firebase,
              and Git to master the craft. I thrive on continuous learning,
              eagerly absorbing new skills to enhance my capabilities.
            </p>
            <Tabs
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="home" title="Skill">
                <li>Html</li>
                <li>Css</li>
                <li>Bootstrap</li>
                <li>Javascript</li>
                <li>React js</li>
              </Tab>
              <Tab eventKey="profile" title="Education">
                <li>Bachelor of Computer Application</li>
                <li>vtp</li>
              </Tab>
            </Tabs>
          </div>
        </Row>
      </Container>

      <Container id="action2" fluid className="back-3">
        <Row>
          <h1 className="text-5 mt-5">Projects</h1>
        </Row>
        <Row>
        <Tabs
      id="controlled-tab-example"
     
      className="mb-3 mt-5 justify-content-center"
    >
      <Tab eventKey="home"  className="text-6" title="ALL" >
       
<div className="pj1 mb-5">
  <div className="im1">
    <a href="https://study-viren.web.app/">
    <img src={img3} className="img3 " alt="" />
    </a>
  </div>
  <div className="im2">
    <a href="https://architecture-viren.web.app/">
    <img src={img4} className="img4  " alt="" />

    </a>
  </div>
</div>
       
       
      </Tab>
      <Tab eventKey="profile" className="text-6" title="HTML/CSS">
      <div className="pj2 mb-3 im3">
    <a href="https://study-viren.web.app/">
    <img src={img3} className="img3 " alt="" />

    </a>
  </div>
      </Tab>
      <Tab eventKey="contact" className="text-6" title="BOOTSTRAP" >
      <div className="pj3 mb-3 im4">
    <a href="https://architecture-viren.web.app/">
    <img src={img4} className="img4  " alt="" />

    </a>
  </div>
      </Tab>
    </Tabs>
        </Row>
      </Container>
      <Container>
        <Row>
        <h1 className="con1">Contact</h1>
        </Row>
      </Container>

      <Container id="table">
        <Row className="mb-5">
          
          <div className="col-lg-6 col-md-6 col-sm-6">
            <h1>Let's Connect</h1>
            <p className="cont">I'm currently looking for new opportunities,
               my inbox is always open. Whether you have
               a question or just want to say hi, I'll 
               try my best to get back to you!</p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
          Your Email <br />
          <input className="form1" type="text" /><br /><br />
          Subject <br />
          <input className="form1" type="text" /><br /><br />
          Message <br />
          <input className="form1" type="text" /><br /><br />
              <a href="#submit"> <button className="btn3">Submit</button></a>
          </div>
          
        </Row>
      </Container>
      
      
    </>
  );
}