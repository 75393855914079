import React from 'react'
import './App.css';


import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './componrt.js/Home'


export default function App() {
  return (
  <>
  <Home/>
  </>
   
   
  )
}
